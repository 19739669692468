<template>
  <ul class="topic">
    <li
      v-for="(item, index) in teachers"
      :key="index"
      @click="
        $router.push({
          name: 'teacher',
          params: { teacher_id: item.id }
        })
      "
    >
      <van-image lazy-load round fit="cover" :src="item.avatar" />
      <p class="cate_name">{{ item.title }}</p>
    </li>
  </ul>
</template>

<script>
export default {
  props: {
    teachers: {
      type: Array
    },
    clickType: {
      type: String,
      default: 'course'
    }
  }
}
</script>

<style lang="less" scoped>
// 专题
.topic {
  display: flex;
  padding-bottom: 10px;
  background-color: #fff;
  direction: rtl;
  overflow: scroll;
  li {
    display: block;
    // margin: 10px;
    text-align: center;
    .van-image {
      width: 240px;
      height: 200px;
      border-radius: 14px !important;
      object-fit: cover;
      margin: 14px;
      vertical-align: top;
    }
    .cate_name {
      font-size: 28px;
      text-align: center;
      padding: 0 20px;
      color: #3a3a3a;
      margin-bottom: 10px;
    }
  }
}
</style>
